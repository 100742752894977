<template>
<div class="formation page">
  <headers title="个人资料"></headers>
  <div class="scroll">
    <div class="list">
      <div class="item flexCenter">
        <div class="left">头像</div>
        <div class="right flexCenter">
          <div class="content">
            <div class="avatar">
              <input
                  class=" uploadImg"
                  id="uploadIcon"
                  ref="uploadIcon"
                  @change="uploadImg($event)"
                  type="file"
                  accept="image/*"
              />
              <van-image
                  width="50"
                  height="50"
                  round
                  :src="userInfo.photo"
              />
            </div>
          </div>
          <van-icon name="arrow" size="20" color="rgb(204, 204, 204)"/>
        </div>
      </div>
      <div class="item flexCenter" @click="toEditFormation(1)">
        <div class="left">昵称</div>
        <div class="right flexCenter">
          <div class="content grey">{{userInfo.username}}</div>
          <van-icon name="arrow" size="20" color="rgb(204, 204, 204)"/>
        </div>
      </div>
      <div class="item flexCenter" @click="toEditFormation(2)">
        <div class="left">绑定手机</div>
        <div class="right flexCenter">
          <div class="content">{{userInfo.account}}</div>
          <van-icon name="arrow" size="20" color="rgb(204, 204, 204)"/>
        </div>
      </div>
      <div class="item flexCenter" >
        <div class="left">信用分</div>
        <div class="right flexCenter">
          <div class="content grey">{{credit_score}}</div>
          <van-icon name="arrow" size="20" color="rgb(204, 204, 204)"/>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import headers from "@/components/headers.vue";
import axios from "axios";

export default {
  name: "formation",
  components: {
    headers
  },
  data() {
    return {
      userInfo: {},
      credit_score: ''
    }
  },
  mounted() {
    this.userInfo = JSON.parse(this.$store.getters["getUserInfo"])
    this.getUserName();
    this.getAvatarUrl();
    this.getAccount();
    this.getXYKInfo();
  },
  methods: {
    getUserName(){
      $api.getUserName({
        token: this.$store.getters["getToken"]
      }).then(res => {
        this.userInfo.username = res.data.username;
      })
    },
    getAvatarUrl(){
      $api.getAvatarUrl({
        token: this.$store.getters["getToken"]
      }).then(res => {
        this.userInfo.photo = res.data.photo;
      })
    },
    getAccount(){
      $api.getAccount({
        token: this.$store.getters["getToken"]
      }).then(res => {
        this.userInfo.account = res.data.account;
      })
    },
    getXYKInfo(){
      $api.xinxi_credit_score({
        token: this.$store.getters["getToken"]
      }).then(res => {
        this.credit_score = res.data.credit_score;
      })
    },
    uploadImg(e) {
      console.log(e)
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      let src = ''
      reader.onload = () => {
        this.userInfo.photo = reader.result;
      };
      let formData = new FormData();//通过formdata上传
      formData.append('photo',e.target.files[0]);
      // alert(this.userInfo.photo)
      $api.Uploads_img(formData).then(res => {
        this.userInfo.photo = res.data;
        res.data && $api.set_info_photo({
          token: this.$store.getters['getToken'],
          photo: res.data
        }).then(res => {
        })
      })
    },
    toEditFormation(type){
      this.$router.push({
        path: "/editFormation",
        query: {
          type
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.formation {
  .list {
    padding: 0 15px 16px;
    .item {
      height: 68px;
      justify-content: space-between;
      border-bottom: 1px solid #f0f0f0;
      .left {
        font-size: 15px;
      }
      .right {
        font-size: 15px;
        .content {
          margin-right: 5px;
          .avatar {
            position: relative;
            .uploadImg {
              width: 100%;
              height: 55px;
              position: absolute;
              left: 0;
              top: 0;
              opacity: 0;
              z-index: 2;
            }
          }
        }
        .grey {
          color: #999;
        }
      }
    }
  }
}
</style>